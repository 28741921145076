// i18next-extract-mark-ns-start food-truck-pos-system

import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { OrderedList } from "components/List";
import { Section, SectionActions, SectionHeader } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

import styled from "styled-components";
import { Flex } from "components/Flex";
import React from "react";
import { DownloadOnAppleStore, IosDownloadLink } from "components/DownloadOnAppleStore";
import { AndroidDownloadLink, DownloadOnGooglePlay } from "components/DownloadOnGooglePlay";
import Bold from "components/Bold";
import { AnchorLink } from "components/AnchorLink";
import { MoneiVideoSection } from "components/landings/MoneiVideoSection";
import { CompatibleWith } from "components/monei-pay/CompatibleWith";
import IndexBackground from "components/monei-pay/IndexBackground";
import { BlogLink } from "components/links/Blog";
import { InternalPageLink } from "components/links/Pages";
import CardsContainer from "components/CardsContainer";
import { Card } from "components/Card";
import cards from "images/credit-cards.svg";
import mobile_payment from "images/mobile_payment.svg";
import money from "images/money.svg";
import site_template from "images/site_template.svg";
import clock from "images/clock.svg";
import person from "images/person.svg";
import { SignUpLink } from "components/links/Other";
import { StyledDiv } from "components/StyledSx";
import { Background } from "components/Background";
import { QrGroup } from "components/monei-pay/QrGroup";
import { RotatedScreenshots } from "components/monei-pay/RotatedScreenshots";
import { MainTitleContent } from "components/monei-pay/MainTitleContent";
import { SupportLink } from "components/links/Support";
import { GradientBox, GradientCtaImage, GradientSectionDescription } from "components/landings/GradientBox";
import integrated_payments_header_image from "images/integrated_payments_header_image.png";
import { ButtonLink } from "components/Button";

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const RetailPosSytem: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Food Truck POS System')}
        description={t(
          'Save time and money. Replace your food truck’s POS system with your phone or tablet. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>The best food truck POS system alternative</Trans>
              </SectionHeader>
              <Trans parent="p">
                When you’re busy preparing delicious food, worrying about whether your POS system
                will work should be your last concern. Battery problems, network issues, missing
                receipt paper, and more are all issues you may encounter with a traditional food
                truck POS system.
              </Trans>
              <Trans parent="p">
                Whether you own a single food truck or manage a fleet of them, accept payments with
                a secure, reliable, and efficient system on your own mobile device. Ditch the bulky,
                expensive, and unreliable food truck POS hardware and add the MONEI Pay app to as
                many mobile devices as you need to collect card, Google Pay, Apple Pay, PayPal, and
                Bizum payments at your food truck.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content>
        <Section centered>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <SectionHeader underline style={{textAlign: 'center', width: '100%'}}>
              <Trans>
                Advantages of MONEI Pay over your existing <br />
                food truck POS system
              </Trans>
            </SectionHeader>
            <p style={{textAlign: 'center'}}>
              <Trans>
                MONEI Pay is already used by many{' '}
                <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
                  restaurants
                </InternalPageLink>{' '}
                in Spain. Accept payments on your phone with QR codes or payment requests to provide
                a quick and frictionless customer experience.
              </Trans>
            </p>

            <CardsContainer sx={{marginTop: '100px'}}>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
                <CardWrapper>
                  <Trans>
                    Accept more{' '}
                    <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like{' '}
                    <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/google-pay">
                      Google Pay
                    </InternalPageLink>, <InternalPageLink slug="payment-methods/paypal">
                      PayPal
                    </InternalPageLink>, and{' '}
                    <InternalPageLink slug="payment-methods/apple-pay">
                      Apple Pay
                    </InternalPageLink>,{' '}
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={money} alt="" style={{top: -48}} />
                <CardWrapper>
                  <Trans>
                    Save up to 50% on{' '}
                    <InternalPageLink slug="pricing">transaction fees</InternalPageLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
                <CardWrapper>
                  <Trans>Use your phone instead of an expensive and unreliable dataphone</Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={site_template} alt="" style={{top: -50, width: 95}} />
                <CardWrapper>
                  <Trans>
                    <SupportLink slug="/articles/8797620433937">Customize</SupportLink> your mobile
                    payment page
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={clock} alt="" style={{top: -65, width: 85}} />
                <CardWrapper>
                  <Trans>Process payments instantly</Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={person} alt="" style={{top: -60, width: 90}} />
                <CardWrapper>
                  <Trans>
                    Offer a{' '}
                    <BlogLink slug="frictionless-payments">
                      frictionless payment experience
                    </BlogLink>
                  </Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </Flex>
        </Section>
        <Flex alignItems="center" direction="column">
          <Trans parent="p">
            👇 <Bold>Download your mobile payment app for food trucks today</Bold> 👇
          </Trans>
          <Flex>
            <div style={{marginRight: '16px'}}>
              <DownloadOnAppleStore />
            </div>
            <DownloadOnGooglePlay />
          </Flex>
        </Flex>
      </Content>
      <Background style={{marginTop: '50px'}}>
        <Content>
          <Section sx={{paddingTop: {sm: '100px'}, alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <SectionHeader style={{fontSize: '2rem'}} underline>
                <Trans>How does MONEI Pay for food trucks work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Taking payments at your food truck with MONEI Pay is simple. Follow these steps:
              </Trans>
              <OrderedList>
                <Trans parent="li">
                  <SignUpLink>Create your MONEI account</SignUpLink>
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/6167527597457">Download</SupportLink> the MONEI Pay
                  app to your smartphone or tablet (you can also access MONEI Pay at{' '}
                  <AnchorLink href="https://pay.monei.com/">pay.monei.com</AnchorLink> from any
                  device/browser)
                </Trans>
                <Trans parent="li">
                  Enter the amount to{' '}
                  <SupportLink slug="/articles/4417709618193">
                    create digital QR code payments
                  </SupportLink>
                </Trans>
                <Trans parent="li">
                  Have your client scan the QR code to pay or enter their mobile number to{' '}
                  <SupportLink slug="/articles/12253203762449">send a payment request</SupportLink>
                </Trans>
                <Trans parent="li">Customer scans the QR code to pay</Trans>
                <Trans parent="li">
                  Client selects their preferred{' '}
                  <SupportLink slug="/articles/4417709486737">payment method</SupportLink>
                </Trans>
                <Trans parent="li">Client finishes the payment with the tap of a button</Trans>
                <Trans parent="li">Funds are instantly deposited into your account</Trans>
              </OrderedList>
              <Trans parent="p">
                <Bold>Do you need to charge your customers later or for catering?</Bold> You can also use MONEI pay to{' '}
                <BlogLink slug="email-payment-link">send payment links via email</BlogLink>, SMS, or
                WhatsApp with{' '}
                <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Try MONEI Pay</Trans>
              </SignUpButton>
            </div>
            <RotatedScreenshots />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline>
              <Trans>Cook, serve, and let MONEI Pay deal with the payments</Trans>
            </SectionHeader>
            <Trans parent="p">
              Try MONEI Pay in your food truck now, with no commitment. Open your MONEI
              account, download the <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink> mobile payment app on as many devices as you
              need to start generating digital QR codes and/or sending payment requests for your
              delicious food.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to experience the mobile payment flow.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a platform or marketplace for food trucks?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Use MONEI Connect to integrate payments and help your food truck owners accept more payment methods on the go.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%',
            }}
            src={integrated_payments_header_image} />
        </GradientBox>
      </Content>
    </>
  );
};

export default RetailPosSytem;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "food-truck-pos-system"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
